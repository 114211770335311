
import { defineComponent, ref, Ref } from 'vue'
import Steps from 'primevue/steps'
import { useRouter, useRoute } from 'vue-router'
import { CompaniesAPI } from '@/api/companies.api'
import { CreateCompanyDto } from '@/models/jobBank/interfaces/company'
import { StepsEvent } from '@/models/interfaces/primevue'
import { JobBankRouteNames } from '@/router/route-names'

export default defineComponent({
  components: {
    Steps
  },
  props: {
    isEditing: {
      type: Boolean
    },
    formData: {
      type: Object,
      default: null
    }
  },
  setup (props, { emit }) {
    const formObject: Ref<CreateCompanyDto> = ref({ name: '', sector: '', logo: '', description: '', contacts: [] })
    const router = useRouter()
    const route = useRoute()
    const { jobBankId } = route.params
    let generalInfo = ''
    let contactInfo = ''
    let profile = ''
    const currentPage = ref(0)
    const companyId = jobBankId
    let gotCompany = false
    const getCompany = async () => {
      formObject.value = await CompaniesAPI.findCompany(jobBankId as string)
      gotCompany = true
    }

    if (jobBankId) {
      getCompany()
    }
    const isEditing = () => {
      if (props.isEditing || jobBankId) {
        generalInfo = `/job-bank/companies/view/${jobBankId}/edit/general-info`
        contactInfo = `/job-bank/companies/view/${jobBankId}/edit/contact-info`
        profile = `/job-bank/companies/view/${jobBankId}/edit/profile`
      } else {
        generalInfo = '/job-bank/companies/create/general-info'
        contactInfo = '/job-bank/companies/create/contact-info'
        profile = '/job-bank/companies/create/profile'
      }
    }
    const editDataFromProps = () => {
      if (props.isEditing) {
        formObject.value = { name: props.formData.name, sector: props.formData.sector, logo: props.formData.logo, description: props.formData.description, contacts: props.formData.contacts, _id: props.formData._id }
      }
    }
    isEditing()
    editDataFromProps()
    const items = ref([
      {
        label: 'Informacion general',
        to: generalInfo,
        command: ({ originalEvent }:{originalEvent:any}) => {
          if (currentPage.value > 0) {
            currentPage.value = 0
          } else {
            originalEvent.preventDefault()
          }
        }
      },
      {
        label: 'Informacion de contacto',
        to: contactInfo,
        command: ({ originalEvent }:{originalEvent:any}) => {
          if (currentPage.value > 1) {
            currentPage.value = 1
          } else {
            originalEvent.preventDefault()
          }
        }
      },
      {
        label: 'Crear perfil',
        to: profile,
        command: ({ originalEvent }:{originalEvent:any}) => {
          if (currentPage.value > 2) {
            currentPage.value = 2
          } else {
            originalEvent.preventDefault()
          }
        }
      }
    ])
    const nextPage = (event: StepsEvent) => {
      if (event.pageIndex === 2) {
        if (props.isEditing) {
          CompaniesAPI.updateCompany(formObject.value, props.formData._id)
          router.push({ name: JobBankRouteNames.EDIT_PROFILE_EDITED })
          return
        } else {
          CompaniesAPI.createCompany(formObject.value)
          router.push({ name: JobBankRouteNames.S_PROFILE_CREATED })
          return
        }
      }
      for (const field in event.formData) {
        formObject.value[field as keyof CreateCompanyDto] = event.formData[field as keyof CreateCompanyDto]! as any
      }
      router.push(items.value[event.pageIndex + 1].to)
      currentPage.value = event.pageIndex + 1
    }

    const prevPage = (event: StepsEvent) => {
      if (event.pageIndex === 0) {
        router.push({ name: JobBankRouteNames.COMPANIES })
        return
      }
      router.push(items.value[event.pageIndex - 1].to)
      currentPage.value = event.pageIndex - 1
    }

    const cancel = () => {
      router.push({ name: JobBankRouteNames.COMPANIES })
      currentPage.value = 0
    }

    return {
      formObject,
      companyId,
      items,
      nextPage,
      prevPage,
      cancel,
      gotCompany
    }
  }
})
