import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, withCtx as _withCtx, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-a15ccb88")
const _hoisted_1 = { class: "stepper" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "border" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Steps = _resolveComponent("Steps")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Steps, {
          model: _ctx.items,
          readonly: false,
          items: _ctx.items
        }, null, 8, ["model", "items"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_view, {
        formData: _ctx.formObject,
        companyId: _ctx.companyId,
        isEditing: _ctx.isEditing,
        onPrevPage: _ctx.prevPage,
        onNextPage: _ctx.nextPage,
        onCancel: _ctx.cancel
      }, {
        default: _withCtx(({Component}) => [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ], 1024))
        ]),
        _: 1
      }, 8, ["formData", "companyId", "isEditing", "onPrevPage", "onNextPage", "onCancel"])
    ])
  ]))
}